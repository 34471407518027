const getDomain = () =>
  typeof window !== "undefined" &&
  window.location.hostname.split(".").slice(1).join(".");
const domain = getDomain() || "xometry.org";

const isDev = domain == "xometry.test";
const protocol = isDev ? "http" : "https";

const orgSubdomain = "org";
const wwwSubdomain = "www";
const getSubdomain = "get";
const workSubdomain = "work";

const marketingPort = 8000;
const getPort = 5001;
const workPort = 5002;

const marketing = `${protocol}://${wwwSubdomain}.${domain}${
  isDev ? `:${marketingPort}` : ""
}`;

const org = `${protocol}://${
  domain === "xometry.org" ? wwwSubdomain : orgSubdomain
}.${domain}${isDev ? `:${marketingPort}` : ""}`;

const get = `${protocol}://${getSubdomain}.${domain}${
  isDev ? `:${getPort}` : ""
}`;

const work = `${protocol}://${workSubdomain}.${domain}${
  isDev ? `:${workPort}` : ""
}`;

export const origins = {
  www: marketing,
  get: get,
  work: work,
  org: org,
};

// Converts an absolute link such as https://www.xometry.com/abc
// to a link that corresponds to the current environment.
// Example: https://www.xometry.com/abc --> https://www.staging.xometry.net/abc
export const buildUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    if (!urlString) return "/404";
    return urlString;
  }

  const { pathname, hash, origin } = url;
  const [subdomain, host] = url.host.split(".");
  const topLevelDomain = origin.split(".").pop();

  // Build url if it is a xometry link
  if (
    host === "xometry" &&
    (subdomain === wwwSubdomain ||
      subdomain === getSubdomain ||
      subdomain === workSubdomain ||
      subdomain === orgSubdomain)
  ) {
    // Returns the new url by using the subdomain variable ('www', 'get', 'work') as key for the
    // origins object. This is possible because of the second half of the if statement above
    // that checks for the subdomain variable to be one of the keys in the origins object.
    return `${origins[subdomain]}${pathname}${hash}`;
  } else if (topLevelDomain === orgSubdomain) {
    // special case for xometry.org links
    return `${origins[topLevelDomain]}${pathname}${hash}`;
  }

  return urlString;
};

export const buildUrlsOnPage = () => {
  for (const link of document.links as any) {
    link.href = buildUrl(link.href);
  }
};
