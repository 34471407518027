// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-components-layout-layout-tsx": () => import("./../src/components/layout/layout.tsx" /* webpackChunkName: "component---src-components-layout-layout-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-50-x-index-tsx": () => import("./../src/pages/50x/index.tsx" /* webpackChunkName: "component---src-pages-50-x-index-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../src/templates/home/home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */)
}

