module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"xometry-foundation","defaultLang":"en-us","langs":["en-us"],"accessToken":"MC5ZS2dPU1JBQUFDSUF5eU9F.GlJQ77-977-9AO-_ve-_ve-_vQgrYe-_vQFbQSRa77-977-9D--_ve-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vQY","previewPath":"/previewPath","previews":true,"omitPrismicScript":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMCCM7","includeInDevelopment":true,"routeChangeEventName":"marketing-site-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
