import { Prismic__Document, Prismic_Meta } from "../types/graphql";
import { Document } from "prismic-javascript/d.ts/documents";

export const previewLinkResolver = (doc: Document) => {
  return (
    previewLinkResolverPath(doc.type) + previewLinkResolverQueryParams(doc)
  );
};

const previewLinkResolverQueryParams = (doc: Document) => {
  return (
    "?lang=" + (doc.lang || "en-us") + ((doc.uid && "&uid=" + doc.uid) || "")
  );
};

export const previewLinkResolverPath = (type: string) => {
  let path = "";

  switch (type) {
    case "standard_landing_page":
      path = `/standard-landing-page`;
      break;
    case "header_links":
      path = `/header-links`;
      break;
    case "header_ctas":
      path = `/header-ctas`;
      break;
  }

  return "/preview" + path;
};

// Convert query string to object
// Example "lang=en-us&uid=default"
// returns {lang: "en-us", uid: "default"}
const parseQueryString = (queryString: string) => {
  let query: any = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

// Returns the uid of a preview url
export const getPreviewDocUID = () => {
  if (typeof window !== "undefined")
    return parseQueryString(window.location.search).uid;
};

/** A prismic document */
export type DocumentLink = Omit<Prismic__Document, "_meta"> & {
  _meta: Pick<Prismic_Meta, "type" | "uid">;
};

export function linkTo(doc: DocumentLink, buildingPage?: boolean) {
  let path = "/";
  const meta = doc._meta;

  switch (meta.type) {
    case "standard_landing_page":
      path = `/` + meta.uid;
      break;
  }

  return path;
}
