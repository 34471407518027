import "@xometry/xometry_loft/deps/fontawesome-pro/css/all.min.css";
import "@xometry/xometry_loft/deps/normalize.css";
import "@xometry/xometry_loft/deps/fonts.css";
import { registerLinkResolver } from "gatsby-source-prismic-graphql";
import { previewLinkResolver } from "./src/utils/contentUtils";
import smooth from "smooth-scroll";
import { CookiesProvider } from "react-cookie";
import { buildUrlsOnPage } from "./src/utils/environment-urls";

// Link resolver for preview
registerLinkResolver(previewLinkResolver);

// update meta og:url and canonical link tags with relative URLs
const onRouteUpdate = ({ location }) => {
  // if path is localized to default language, use non-localized path
  let pathName = `${location.pathname}`;
  let defaultLangPath = "/en-US";
  if (pathName.startsWith(defaultLangPath)) {
    pathName = pathName.substring(defaultLangPath.length);
  }

  let url = `${location.origin}${pathName}${location.hash}`;

  const ogUrl = document.createElement("meta");
  ogUrl.setAttribute("property", "og:url");
  ogUrl.setAttribute("content", url);

  const canonicalLink = document.createElement("link");
  canonicalLink.setAttribute("rel", "canonical");
  canonicalLink.setAttribute("href", url);

  const header = document.querySelector("head");
  header.appendChild(ogUrl);
  header.appendChild(canonicalLink);

  if (
    location.pathname.includes("/preview") ||
    document.referrer.includes("/preview")
  ) {
    const prismicScript = document.createElement("script");
    prismicScript.setAttribute(
      "src",
      "https://static.cdn.prismic.io/prismic.min.js"
    );
    prismicScript.setAttribute("defer", true);
    header.appendChild(prismicScript);
  }

  window.analytics && window.analytics.page("Foundation");

  buildUrlsOnPage();
};

const wrapRootElement = ({ element }) => {
  return <CookiesProvider>{element}</CookiesProvider>;
};

export { onRouteUpdate, wrapRootElement };

const headerSelector = "[data-smooth-scroll-header]";

// apply smooth scroll to all anchors
smooth('a[href*="#"]', {
  topOnEmptyHash: true,
  header: headerSelector,
  speed: 500,
  speedAsDuration: true,
  offset: function () {
    const padding = 20;
    return -document.querySelector(headerSelector).offsetTop + padding;
  },
});
